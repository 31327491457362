import { Box, type BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import {
  Typography,
  type TypographyPropsType,
} from '@astronautsid/wpe-astro-ui/components/atoms/Typography'

const ProductLabelPromotion = ({
  bgColor,
  textColor,
  text,
  boxPropsWrapper,
  textProps,
}: {
  bgColor: string
  textColor: string
  text: string
  boxPropsWrapper?: BoxPropsType
  textProps?: TypographyPropsType
}) => (
  <Box display="inline-block" maxWidth="100%">
    <Box
      bgcolor={bgColor}
      px="4px"
      py="2px"
      borderRadius="4px"
      display="flex"
      alignItems="center"
      overflow="hidden"
      {...boxPropsWrapper}
    >
      <Typography
        variant="caption-tinyBold"
        color={textColor}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        {...textProps}
      >
        {text}
      </Typography>
    </Box>
  </Box>
)

export default ProductLabelPromotion
