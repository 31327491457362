'use client'

import { type ReactNode } from 'react'
import Link from 'next/link'
import { Box, BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { useTheme } from '@astronautsid/wpe-astro-ui/theme'

import Image from 'components/Image'
import { ASTRO_SITE_LINKS, ASTRO_SOCIALS } from './constant'
import InstallAppPlatform from './InstallAppPlatform'

type FooterPropsType = {
  boxProps?: BoxPropsType
  bottomContent?: ReactNode
}

const Footer = ({ boxProps, bottomContent }: FooterPropsType) => {
  const theme = useTheme()

  const renderAstroSiteLinks = () => (
    <Box>
      <Typography variant="headline-tiny">Cari semua di Astro!</Typography>
      <Box display="inline-flex" alignItems="center" gap="4px" flexWrap="wrap" mt="4px">
        {ASTRO_SITE_LINKS.map((i) => {
          const key = i.name

          return (
            <Box display="flex" alignItems="center" gap="5px" key={key}>
              <Link
                href={i.link}
                rel="noreferrer noopener"
                style={{ display: 'block' }}
                target="_blank"
              >
                <Typography variant="caption-small" color="textColor.secondaryDark">
                  {i.name}
                </Typography>
              </Link>
              <Box width="1px" height="14px" bgcolor={theme.palette.textColor.secondaryDark} />
            </Box>
          )
        })}
      </Box>
    </Box>
  )

  const renderSocials = () => (
    <Box mt="16px">
      <Typography variant="headline-tiny">Social Media</Typography>
      <Box display="flex" gap="8px" mt="6px">
        {ASTRO_SOCIALS.map((i) => {
          const key = i.name

          return (
            <Image
              key={key}
              src={i.imgUrl}
              alt={i.alt}
              width={24}
              height={24}
              style={{ background: 'initial' }}
              linkprops={{
                href: i.link,
                target: '_blank',
              }}
            />
          )
        })}
      </Box>
    </Box>
  )

  return (
    <Box p="16px" {...boxProps}>
      {bottomContent}
      {renderAstroSiteLinks()}
      <InstallAppPlatform boxProps={{ mt: '16px' }} />
      {renderSocials()}
    </Box>
  )
}

export default Footer
