import { useRef, useEffect, useCallback } from 'react'
import noop from 'utils/function/noop'

interface IntersectHooksOptionsInterface {
  callback: () => void
  onlyOnce?: boolean
  options?: IntersectHooksConfigInterface
  onLeaveCallback?: () => void
}

interface IntersectHooksConfigInterface {
  root?: Element | Document | null
  rootMargin?: string
  threshold?: number | number[]
}

const IO_OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: [0.05, 0.3, 0.6, 0.95],
}

/**
 * @function useIntersection
 * @param param
 */
function useIntersection<T extends HTMLElement = HTMLElement>(
  param: IntersectHooksOptionsInterface,
) {
  const { callback, onlyOnce = false, options = IO_OPTIONS, onLeaveCallback } = param
  const intersected = useRef(false)
  const targetRef = useRef<T>(null)

  /**
   * @function _onIntersection
   * @param entries
   * @param observer
   * @returns {void}
   */
  const onIntersection = useCallback(
    ([entry]: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      const isIntersecting = (entry && entry.isIntersecting) ?? false
      if (!isIntersecting) {
        if (onLeaveCallback) onLeaveCallback()
        return
      }

      /**
       * If onlyOnce, disconnect observer and mark
       */
      if (onlyOnce) {
        intersected.current = true
        observer.disconnect()
      }

      callback()
    },
    [callback, onlyOnce, onLeaveCallback],
  )

  useEffect(() => {
    const isSupportedIO = typeof 'IntersectionObserver' !== 'undefined'
    if (!isSupportedIO || !targetRef.current) {
      return noop
    }

    /**
     * If previously already intersected and onlyOnce,
     * exit. Skipping creating new IntersectionObserver instance
     */
    if (intersected.current && onlyOnce) {
      return noop
    }

    const observer = new IntersectionObserver(onIntersection, {
      ...IO_OPTIONS,
      ...options,
    })

    observer.observe(targetRef.current)
    return () => {
      observer.disconnect()
    }
  }, [onIntersection, onlyOnce, options])

  return targetRef
}

export default useIntersection
