import Image from 'components/Image'

const ProductLabelImage = ({
  imageUrl,
  width = 20,
  height = 20,
  alt,
}: {
  imageUrl: string
  width?: number
  height?: number
  alt: string
}) => <Image src={imageUrl} width={width} height={height} alt={alt} />

export default ProductLabelImage
