'use client'

import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Button } from '@astronautsid/wpe-astro-ui/components/atoms/Button'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Close } from '@astronautsid/wpe-icons'
import { useTranslations } from 'next-intl'

import Image from 'components/Image'
import { ASSET_PREFIX } from 'config/constants'

import useAppInstallBanner from './useAppInstallBanner'

const AppInstallBanner = () => {
  const { bottom, isWebview, handleToOpen, handleToClose } = useAppInstallBanner()
  const t = useTranslations('global')

  if (isWebview) return null

  if (bottom === null) return bottom

  return (
    <Box
      paddingY="8px"
      pr="18px"
      pl="8px"
      display="flex"
      justifyContent="space-between"
      position="fixed"
      bottom={bottom}
      width="100%"
      maxWidth="430px"
      zIndex={10}
      sx={{
        background:
          'linear-gradient(91deg, #76CFFD 17.33%, #FFF 129.14%), var(--bgColor-light, #FFF)',
      }}
      className="app-installer-banner"
    >
      <Box display="flex" justifyContent="space-between" gap="8px" alignItems="center">
        <Box onClick={handleToClose}>
          <Close size={16} />
        </Box>
        <Image
          src={`${ASSET_PREFIX}/astro-logo.png`}
          alt="astro"
          width={32}
          height={32}
          style={{ borderRadius: 8, background: 'none' }}
        />
        <Typography variant="caption-small" width="196px">
          {t('appMessage')}
        </Typography>
      </Box>
      <Button size="tiny" sx={{ borderRadius: 50 }} onClick={handleToOpen}>
        {t('open')}
      </Button>
    </Box>
  )
}

export default AppInstallBanner
