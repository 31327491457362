/* eslint-disable react-hooks/exhaustive-deps */

'use client'

import { usePathname } from 'next/navigation'
import { useTranslations } from 'next-intl'
import { useMemo, useEffect } from 'react'
import { useTheme } from '@astronautsid/wpe-astro-ui/theme'
import { useQuery } from '@tanstack/react-query'

import { getCart } from 'utils/apiList/lite/cart'
import useUserStore from 'store/user'
import useRouter from 'hooks/useRouter'
import useCartStore from 'store/cart/slice'

type MenuItemType = 'Home' | 'Cart' | 'Order' | 'Profile'
interface MenuItemInterface {
  key: MenuItemType
  title: string
  url: string
  iconColor: string
  fontColor: string
  totalNotif?: number
}

const useBottomNavigation = () => {
  const theme = useTheme()
  const router = useRouter()
  const pathname = usePathname()
  const { isLoggedIn, customerAddress } = useUserStore((state) => state)
  const { setCartMenuTotal, cartMenuTotal } = useCartStore()

  const handleRouteTo = (navigationUrl: string) => {
    router.push(navigationUrl)
  }
  const tNavMenu = useTranslations('global.navMenu')

  const { data: dataCart, refetch: refetchCartQuery } = useQuery({
    queryKey: ['cart-count', customerAddress.location_id],
    enabled: isLoggedIn,
    queryFn: () =>
      getCart({
        cart_types: `INSTANT`,
        lite: true,
        send_as_gift: false,
      }),
  })

  useEffect(() => {
    if (isLoggedIn) {
      refetchCartQuery()
    }
  }, [])

  useEffect(() => {
    setCartMenuTotal(dataCart?.data?.data?.carts?.[0]?.items?.length || 0)
  }, [dataCart])

  const menus = useMemo(() => {
    const {
      palette: {
        iconColor: { defaultAlt, primary },
        textColor: { link, secondaryDark },
      },
    } = theme

    const defaultProperties = { iconColor: defaultAlt, fontColor: secondaryDark }

    const data: MenuItemInterface[] = [
      { key: 'Home', title: tNavMenu('home'), url: '/', ...defaultProperties },
      {
        key: 'Cart',
        title: tNavMenu('cart'),
        url: '/cart',
        totalNotif: cartMenuTotal,
        ...defaultProperties,
      },
      { key: 'Order', title: tNavMenu('order'), url: '/order/history', ...defaultProperties },
      { key: 'Profile', title: tNavMenu('profile'), url: '/account', ...defaultProperties },
    ]

    const getIndexMenuActive = data.findIndex((el) => el.url.includes(pathname))

    if (getIndexMenuActive >= 0) {
      data[getIndexMenuActive].iconColor = primary
      data[getIndexMenuActive].fontColor = link
    }

    return data
  }, [pathname, tNavMenu, theme, cartMenuTotal])

  return {
    state: { pathname, menus, theme },
    handler: { handleRouteTo },
  }
}

export default useBottomNavigation
