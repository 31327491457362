'use client'

import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { Account, Cart, History, HomeNav } from '@astronautsid/wpe-icons'

import useBottomNavigation from './useBottomNavigation'

interface IconPropsInterface {
  color: string
}

const icons = {
  Home: (props: IconPropsInterface) => <HomeNav type="solid" {...props} />,
  Cart: (props: IconPropsInterface) => <Cart type="solid" {...props} />,
  Order: (props: IconPropsInterface) => <History type="solid" {...props} />,
  Profile: (props: IconPropsInterface) => <Account type="solid" {...props} />,
}

const BottomNavigation = () => {
  const {
    state: { menus, theme },
    handler: { handleRouteTo },
  } = useBottomNavigation()

  return (
    <Box
      display="flex"
      bgcolor={theme.palette.bgColor.light}
      alignItems="center"
      justifyContent="space-around"
      className="bottom-navigation"
    >
      {menus.map(({ key, title, url, iconColor: color, fontColor, totalNotif }) => (
        <Box
          textAlign="center"
          key={title}
          onClick={() => handleRouteTo(url)}
          width="100%"
          style={{ cursor: 'pointer' }}
          py="7px"
          display="flex"
          justifyContent="center"
        >
          <Box flexDirection="column" alignItems="center" display="flex" position="relative">
            {icons[key]({ color })}
            <Typography variant="caption-tinyBold" color={fontColor} mt="2px">
              {title}
            </Typography>
            {!!totalNotif && (
              <Box
                width="16px"
                height="16px"
                bgcolor={theme.palette.bgColor.secondary}
                borderRadius="100px"
                position="absolute"
                right="5px"
                top="-5px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography color="white" variant="caption-tinyBold" lineHeight="12px">
                  {totalNotif}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default BottomNavigation
