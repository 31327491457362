import { Box, BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'

import Image from 'components/Image'
import { TrackEventsPropertiesType } from 'lib/mixpanel/mixpanelTracker'
import { trackClickButtonDownloadApps } from 'utils/tracking/mixpanel/mixpanelEventActions'
import useUserStore from 'store/user'

import { INSTALL_APP_PLATFORM } from './constant'

type InstallAppPlatformPropsType = {
  boxProps?: BoxPropsType
}

const InstallAppPlatform = ({ boxProps }: InstallAppPlatformPropsType) => {
  const { customerAddress } = useUserStore((state) => state)

  const handleClick = (item: (typeof INSTALL_APP_PLATFORM)[0]) => {
    const properties: TrackEventsPropertiesType = {
      eventAction: `click download apps - banner`,
      eventLabel: item.store,
      locationId: `${customerAddress.location_id}`,
    }

    trackClickButtonDownloadApps(properties)
  }

  return (
    <Box {...boxProps}>
      <Typography variant="headline-tiny">Gunakan Aplikasi Astro</Typography>
      <Box display="flex" alignItems="center" gap="8px" mt="6px">
        {INSTALL_APP_PLATFORM.map((i) => {
          const key = i.name

          return (
            <Image
              key={key}
              src={i.imgUrl}
              alt={i.alt}
              width={81}
              height={24}
              linkprops={{ target: '_blank', href: i.link, onClick: () => handleClick(i) }}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default InstallAppPlatform
