import type { ProductLabelType } from 'utils/apiList/lite/searchProduct'

export const productNotFoundStatusCheck = (statusCode: number) =>
  statusCode === 400 || statusCode === 404

const labelType = {
  IMAGE: 1,
  PROMOTION: 2,
}

export const parseProductLabels = (productLabels: ProductLabelType[]) => {
  const labelImage = productLabels
    .filter((i) => i.label_type_id === labelType.IMAGE)
    .find((i) => i.is_primary)

  const labelPromotion = productLabels
    .filter((i) => i.label_type_id === labelType.PROMOTION)
    .find((i) => i.is_primary)

  return {
    labelImage,
    labelPromotion,
  }
}
