'use client'

import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { color } from '@astronautsid/wpe-astro-ui/tokens/color'

const CounterCart = ({ counter }: { counter: number }) => (
  <Box
    position="absolute"
    top="18%"
    right="16%"
    zIndex={1}
    width={12}
    height={12}
    display="flex"
    alignItems="center"
    justifyContent="center"
    borderRadius="999px"
    bgcolor={color.bgColor.red}
  >
    <Typography color={color.bgColor.light} variant="caption-tiny">
      {counter}
    </Typography>
  </Box>
)

export default CounterCart
