import { ASSET_PREFIX, ASTRO_SITE_BASE_URL, ASTRO_BLOG_BASE_URL } from 'config/constants'

export const ASTRO_SITE_LINKS = [
  {
    name: 'Astro Goods',
    link: `${ASTRO_SITE_BASE_URL}/astro-goods`,
  },
  {
    name: 'Jaminan Segar',
    link: `${ASTRO_SITE_BASE_URL}/jaminan-segar`,
  },
  {
    name: 'Karir',
    link: `${ASTRO_SITE_BASE_URL}/karir`,
  },
  {
    name: 'Referral',
    link: `${ASTRO_SITE_BASE_URL}/ajak-belanja`,
  },
  {
    name: 'Tentang Astro',
    link: `${ASTRO_SITE_BASE_URL}/tentang-astro`,
  },
  {
    name: 'Area Layanan',
    link: `${ASTRO_SITE_BASE_URL}/area`,
  },
  {
    name: 'Gabung Mitra Astro',
    link: `${ASTRO_SITE_BASE_URL}/mitra`,
  },
  {
    name: 'Brand Partnership',
    link: `${ASTRO_SITE_BASE_URL}/brand-partnership`,
  },
  {
    name: 'FAQ',
    link: `${ASTRO_SITE_BASE_URL}/faq`,
  },
  {
    name: 'Kebijakan Privasi',
    link: `${ASTRO_SITE_BASE_URL}/privacy-policy`,
  },
  {
    name: 'Syarat & Ketentuan',
    link: `${ASTRO_SITE_BASE_URL}/syarat-ketentuan`,
  },
  {
    name: 'Blog',
    link: ASTRO_BLOG_BASE_URL,
  },
]

export const ASTRO_SOCIALS = [
  {
    name: 'instagram',
    imgUrl: `${ASSET_PREFIX}/img/instagram.svg`,
    link: 'https://www.instagram.com/astronauts.id/',
    alt: 'astro-instagram',
  },
  {
    name: 'facebook',
    imgUrl: `${ASSET_PREFIX}/img/facebook.svg`,
    link: 'https://www.facebook.com/astronauts.id',
    alt: 'astro-facebook',
  },
  {
    name: 'youtube',
    imgUrl: `${ASSET_PREFIX}/img/youtube.svg`,
    link: 'https://www.youtube.com/channel/UCdnBvEWKvBuBTlQ-NssCQsQ',
    alt: 'astro-youtube',
  },
  {
    name: 'linkedin',
    imgUrl: `${ASSET_PREFIX}/img/linkedin.svg`,
    link: 'https://www.linkedin.com/company/astronautsid',
    alt: 'astro-linkedin',
  },
]

export const INSTALL_APP_PLATFORM = [
  {
    name: 'ios',
    store: 'appstore',
    imgUrl: `${ASSET_PREFIX}/img/app-store.svg`,
    link: 'https://astroid.link/astroliteios',
    alt: 'astro-app-store',
  },
  {
    name: 'android',
    store: 'playstore',
    imgUrl: `${ASSET_PREFIX}/img/google-store.svg`,
    link: 'https://astroid.link/astroliteandroid',
    alt: 'astro-google-store',
  },
]
